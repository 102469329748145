import { graphql, useStaticQuery } from 'gatsby';
import { parseNumber } from 'utils/parsing';
import { AllDirectusReviewStat, TrustpilotSummary, ReviewStat } from './customer-reviews.interface';

type Reviews = AllDirectusReviewStat & TrustpilotSummary;

export default (): ReviewStat[] => {
  const { allDirectusReviewStat, trustpilotSummary } = useStaticQuery<Reviews>(graphql`
    query {
      trustpilotSummary {
        review {
          total
          desc
          score
        }
      }
      allDirectusReviewStat {
        edges {
          node {
            directusId
            slug
            provider
            link
            score
            max_score
            sort
            total_reviews
            show
            logo {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fixed(toFormat: WEBP) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
            colour
            logo_width
          }
        }
      }
    }
  `);

  const trustpilotIndex = allDirectusReviewStat.edges.findIndex((stat) => stat.node.provider === 'Trustpilot');
  allDirectusReviewStat.edges[trustpilotIndex].node.score = parseNumber(trustpilotSummary.review.score);
  allDirectusReviewStat.edges[trustpilotIndex].node.total_reviews = parseNumber(trustpilotSummary.review.total);

  return allDirectusReviewStat.edges;
};

export const parseNumber = (str: string): number => {
  let value;
  let decimal = '.';
  value = str.replace(/[^0-9$.,]/g, '');
  if (value.indexOf(',') > value.indexOf('.')) decimal = ',';
  if ((value.match(new RegExp(`\\${decimal}`, 'g')) || []).length > 1) decimal = '';
  if (decimal !== '' && value.length - value.indexOf(decimal) - 1 === 3 && value.indexOf(`-${decimal}`) !== 0) {
    decimal = '';
  }
  value = value.replace(new RegExp(`[^0-9$${decimal}]`, 'g'), '');
  value = value.replace(',', '.');
  return parseFloat(value);
};
